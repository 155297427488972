import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';

export default function FooterAd() {
    return (
        <Box py={4} sx={{ flexGrow: 1 }}>
            <Container maxWidth='xl'>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Grid item container justifyContent="center" alignItems="center" className="grey-box-ads"> Ads Area</Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid item container justifyContent="center" alignItems="center" className="grey-box-ads"> Ads Area</Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid item container justifyContent="center" alignItems="center" className="grey-box-ads"> Ads Area</Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid item container justifyContent="center" alignItems="center" className="grey-box-ads"> Ads Area</Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}