import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import AuthService from "../../services/auth.services";
import { useFormik } from "formik";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import * as Yup from "yup";
import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import logo from "../../assets/images/Logo-Official.png";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const ConfirmEmailVerification = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenValid, setTokenValid] = useState();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (!tokenValid) {
      setLoading(true);
      AuthService.resetPasswordConfirm(token).then((res) => {
        if (res.data.error) {
          toast.error(res.data.error);
        } else if (res.data.message) {
          setEmail(res.data.email);
          setTokenValid(true);
          setLoading(false);
        }
      });
    }
  }, [tokenValid]);

  const initialValues = {
    email: email,
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    confirmPassword: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  const { values, errors, handleChange, handleSubmit, touched, setValues } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        // alert(JSON.stringify(values, null, 2));
        setLoading(true);
        try {
          AuthService.setNewPassword(values, email)
            .then((res) => {
              if (res.data.message) {
                toast.success(res.data.message);
                navigate("/signin");
              }
              setLoading(false);
            })
            .catch((error) => {
              toast.error(error.message);
            });
        } catch (error) {
          toast.error(error.message);
        }
      },
    });

  return (
    <div className="ConfirmEmailVerification">
      {tokenValid ? (
        <Grid container justifyContent="center" pt={{ xs: 2, md: 8 }}>
          <Card>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Box p={4}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Link to="/">
                        <img src={logo} />
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <p className="fptag">Reset Password</p>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <p className="resetlink">Enter your account new password</p>
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="input-full-width"
                  >
                    <Grid item container>
                      <h4>Password</h4>
                    </Grid>

                    <Grid item container>
                      <TextField
                        sx={{ width: "100%" }}
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          style: { borderRadius: "15px" },
                          endAdornment: (
                            <>
                              {showPassword ? (
                                <AiFillEye
                                  onClick={() => setShowPassword(!showPassword)}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <AiFillEyeInvisible
                                  onClick={() => setShowPassword(!showPassword)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </>
                          ),
                        }}
                        value={values.password}
                        onChange={handleChange("password")}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Grid item container>
                      <h4>Confirm Password</h4>
                    </Grid>

                    <Grid item container>
                      <TextField
                        sx={{ width: "100%" }}
                        variant="outlined"
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                          style: { borderRadius: "15px" },
                          endAdornment: (
                            <>
                              {showConfirmPassword ? (
                                <AiFillEye
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <AiFillEyeInvisible
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </>
                          ),
                        }}
                        value={values.confirmPassword}
                        onChange={handleChange("confirmPassword")}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    className="RequestButton"
                    marginTop="20px"
                  >
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      className="reqbtn"
                      type="Submit"
                    >
                      <p className="reqBtnText">CONFIRM</p>
                    </LoadingButton>
                  </Grid>
                </Box>
              </CardContent>
            </form>
          </Card>
        </Grid>
      ) : (
        <p>Sorry!! Not Valid Token</p>
      )}
    </div>
  );
};

export default ConfirmEmailVerification;
