import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import "./screen-news-details.css";
import newsHealingImg from "../../assets/images/articles_UrQy4mBxuGUJJHx9OhQG1.png";
import alsoLikeImg from "../../assets/images/325590724_715067533506171_7413350089816943005_n1.png";
import {
  fetchANewsData,
  getYouMayAlsoLinkNews,
} from "../../services/news/news_details.service";
import ShareLinks from "../../components/shared/share-links/share-links";
import Comment from "../../components/shared/comment-react/comment";
import {
  Link,
  ScrollRestoration,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useParams } from "react-router-dom";
import htmlTagsView from "../../services/htmlTagsView";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/joy/CircularProgress";
import { getSortedCategoryNews } from "../../services/news/news.service";
import { timeCalculation } from "../../services/shared.services";
import Reaction from "../../components/shared/reaction-panel/Reaction";
import CommentView from "../../components/shared/comments-view/CommentView";
import Loading from "../../components/shared/loading/loading";

const ScreenNewsDetails = () => {
  const [id, setId] = useState();
  const [newsDetails, setNewsDetails] = useState();
  const [recentNewsDetails, setRecentNewsDetails] = useState();
  const [reactCount, setReactCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get("id")) navigate("/");
    setId(searchParams.get("id"));
    fetchNewsData();
    fetchYouMayAlsoLinkNews();
  }, [searchParams, id]);

  async function fetchNewsData() {
    setLoading(true);
    await fetchANewsData(searchParams.get("id")).then((res) => {
      setLoading(false);
      if (!res) navigate("/");
      setNewsDetails(res);
      setCommentCount(res.comments.length);
      setReactCount(res.reactions.length);
      setId(searchParams.get("id"));
    });
  }
  async function fetchYouMayAlsoLinkNews() {
    setLoading(true);
    await getYouMayAlsoLinkNews(searchParams.get("id")).then((res) => {
      setLoading(false);
      if (!res.data) navigate("/");
      setRecentNewsDetails(res.data);
      setId(searchParams.get("id"));
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="newsDetailsScreen">
      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1 }}>
          <Grid item container py={2}>
            {newsDetails != undefined && <h1>{newsDetails.title}</h1>}
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              md={8}
              spacing={2}
            >
              <Grid item container>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item>
                    {newsDetails != undefined && (
                      <p>{timeCalculation(newsDetails.publishedDateTime)}</p>
                    )}
                  </Grid>
                  <Grid item pr={8}>
                    <ShareLinks />
                  </Grid>
                </Grid>
              </Grid>
              {newsDetails != undefined ? (
                <Grid item container className="new-details-img">
                  <img
                    src={newsDetails.media[0]}
                    alt={"Placeholder"}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://user-images.githubusercontent.com/2351721/31314483-7611c488-ac0e-11e7-97d1-3cfc1c79610e.png";
                    }}
                  />
                </Grid>
              ) : (
                <Grid item container className="new-details-img">
                  <Skeleton variant="rectangular" className="Skeleton-img" />
                </Grid>
              )}
            </Grid>

            {recentNewsDetails != undefined ? (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="flex-start"
                xs={12}
                md={4}
                className="also-like-section"
              >
                <Grid
                  item
                  container
                  spacing={1}
                  className="youMayLikeNews"
                  textAlign="left"
                >
                  <Grid item container>
                    <h3>You may also like </h3>
                  </Grid>

                  {recentNewsDetails.map((item, index) => {
                    if (index > 3) return;
                    return (
                      <Grid key={index} item container xs={6}>
                        <Grid item container>
                          <Link to={`/detailNews/?id=${item._id}`}>
                            <img src={item.media[0]} />
                          </Link>
                        </Grid>
                        <Grid item container>
                          <Link to={`/detailNews/?id=${item._id}`}>
                            <h3>{item.title}</h3>
                          </Link>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ) : (
              <Grid item container>
                <Skeleton variant="rectangular" className="Skeleton-img" />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} py={2} alignItems="flex-start">
            <Grid item md={10}>
              {newsDetails != undefined && (
                <Grid item className="text-justify" pr={4}>
                  {htmlTagsView(newsDetails.content)}
                </Grid>
              )}

              <Grid item container py={4}>
                <CommentView />
              </Grid>

              <Grid item container>
                {id != undefined ? (
                  <Grid item container>
                    <Grid item container>
                      <Reaction newsId={id} />
                    </Grid>
                    <Grid item container>
                      <Comment
                        newsId={id}
                        commentCount={commentCount}
                        reactCount={reactCount}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container>
                    <Skeleton variant="rectangular" className="Skeleton-img" />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {recentNewsDetails != undefined && (
              <Grid
                item
                container
                md={2}
                className="also-like-section"
                spacing={2}
              >
                <Grid item container>
                  <span className="grey-box-ads"></span>
                </Grid>
                {recentNewsDetails.map((item, index) => {
                  if (index > 3 && index < 6)
                    return (
                      <Grid key={index} item container xs={12}>
                        <Grid item container>
                          <Link to={`/detailNews/?id=${item._id}`}>
                            <img src={item.media[0]} />
                          </Link>
                        </Grid>
                        <Grid item container>
                          <Link to={`/detailNews/?id=${item._id}`}>
                            <h3>{item.title}</h3>
                          </Link>
                        </Grid>
                      </Grid>
                    );
                })}
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default ScreenNewsDetails;
