import React from "react";
import { Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import "./ads-area.css";

const AdsArea = () => {
  return (
    <Box py={4} sx={{ flexGrow: 1 }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item container>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              md={12}
              className="grey-box-ads"
            >
              Ads Area
            </Grid>
          </Grid>
          <Grid item container>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              md={12}
              className="grey-box-ads"
            >
              {" "}
              Ads Area
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className="grey-box-ads"
              >
                {" "}
                Ads Area
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className="grey-box-ads"
              >
                {" "}
                Ads Area
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AdsArea;
