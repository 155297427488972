import axios from "axios";

const weatherConversion = async (location) => {
  const API_KEY = "e41a5814d57a4578bb2124332233001";
  const API_URL = `http://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${location}`;
  try {
    const { data } = await axios.get(API_URL);
    const tempData = data;
    return tempData;
  } catch (error) {
    console.error(error);
  }
};

export default weatherConversion;
