import React from "react";
import axios from "axios";
import commonVar from "../common";

const baseURL = commonVar.baseUrl;

export function fetchCatData(id) {
  return axios
    .get(baseURL + `/news/category-news/:${id}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error);
    });
}

// export function fetchCatNewsData(id) {
//   return axios
//     .get(baseURL + `/news//filer-news-by-category/`)
//     .then((response) => response.data.data)
//     .catch((error) => {
//       console.log(error);
//     });
// }
