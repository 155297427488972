import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "./under-top-news.css";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";

export default function UnderTopNews(data) {
  const underTopNewsDataArr = data.underTopNewsData;
  const underTopNewsData = underTopNewsDataArr.slice(0, 4);

  return (
    <div className="underTopNews">
      <Box sx={{ flexGrow: 1, mt: 2, mb: 2 }}>
        {underTopNewsData.length > 0 ? (
          <Grid
            container
            justifyContent={{ xs: "center" }}
            textAlign={{ xs: "center", md: "left" }}
            spacing={3}
          >
            <Grid item container>
              <h2>{underTopNewsData[0].categoryId.category}</h2>
            </Grid>

            {underTopNewsData.map((item, index) => {
              return (
                <Grid key={index} item container md={3}>
                  <Grid item container>
                    <Link to={`/detailNews/?id=${item._id}`}>
                      <img src={item.media[0]} className="underimg" />
                    </Link>
                  </Grid>
                  <Grid item container py={2}>
                    <Link to={`/detailNews/?id=${item._id}`}>
                      <h3 className="underTitle">{item.title}</h3>
                    </Link>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid container justifyContent="center" alignItem="center">
            <Skeleton
              variant="rectangular"
              style={{ width: "75vw" }}
              height={200}
            />
          </Grid>
        )}
      </Box>
    </div>
  );
}
