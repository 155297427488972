import React from "react";
import axios from "axios";
import commonVar from "../common";

const baseURL = commonVar.baseUrl;

export async function fetchANewsData(id) {
  return await axios
    .get(baseURL + `/news/view-a-news/:${id}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error);
    });
}

export async function fetchImg(id) {
  return await axios
    .get(baseURL + `/media/get-media/:${id}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error);
    });
}

export async function getYouMayAlsoLinkNews(newsId) {
  return await axios
    .get(baseURL + "/news/newsDetailsYouMayLike", { params: { newsId } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
