import React from "react";
import axios from "axios";
import commonVar from "./common";

const baseURL = commonVar.baseUrl;

export async function getSocialLinks() {
  return axios
    .get(baseURL + "/social-links/get-links")
    .then((response) => {
      return (response = response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function timeCalculation(givenDate) {
  const currentDate = new Date();
  givenDate = new Date(givenDate);
  const differenceInMs = currentDate - givenDate;

  let differenceTime;
  let timeUnit;

  if (differenceInMs < 60000) {
    differenceTime = Math.round(differenceInMs / 1000);
    timeUnit = differenceTime > 1 ? "seconds" : "second";
  } else if (differenceInMs < 3600000) {
    differenceTime = Math.round(differenceInMs / 1000 / 60);
    timeUnit = differenceTime > 1 ? "minutes" : "minute";
  } else if (differenceInMs < 86400000) {
    differenceTime = Math.round(differenceInMs / 1000 / 60 / 60);
    timeUnit = differenceTime > 1 ? "hours" : "hour";
  } else if (differenceInMs < 2629800000) {
    differenceTime = Math.round(differenceInMs / 1000 / 60 / 60 / 24);
    timeUnit = differenceTime > 1 ? "days" : "day";
  } else if (differenceInMs < 31557600000) {
    differenceTime = Math.round(differenceInMs / 1000 / 60 / 60 / 24 / 30);
    timeUnit = differenceTime > 1 ? "months" : "month";
  } else {
    differenceTime = Math.round(differenceInMs / 1000 / 60 / 60 / 24 / 365);
    timeUnit = differenceTime > 1 ? "years" : "year";
  }
  return (
    <span>
      {differenceTime} {timeUnit} ago
    </span>
  );
}
