import { Grid, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import cartoonimg from "../../assets/images/pop9.jpg";
import "./Cartoon.css";
import { cartoon } from "../../services/news/news.service";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Cartoon = (data) => {
  const cartoonData = data.cartoonData[0];
  const imageClass = classNames({
    cartoonimg: true,
    "cartoonimg-mob": true,
    "cartoonimg-mobpad": true,
  });
  return (
    <div className="cartoonSection">
      <Grid item container justifyContent="flex-start" alignItems="center">
        <Grid item>
          {cartoonData != undefined ? (
            <Link to={`/detailNews/?id=${cartoonData._id}`}>
              <img src={cartoonData.media[0]} className={imageClass} />
            </Link>
          ) : (
            <Skeleton
              variant="rectangular"
              className="skeleton"
              style={{ height: "30vh" }}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Cartoon;
