import React from "react";
import axios from "axios";
import commonVar from "./common";

const baseURL = commonVar.baseUrl;

export async function getGalleyImages() {
  return axios
    .get(baseURL + "/media/get-gallery-images")
    .then((response) => {
      return (response = response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}
