import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container, Skeleton } from "@mui/material";
import "./hot-news.css";
import { Stack } from "@mui/system";
import { hotNews } from "../../services/news/news.service";
import temp from "../../services/weather_convertion";
import DisplayHtml from "../../services/htmlTagsView";
import { timeCalculation } from "../../services/shared.services";
import { Link } from "react-router-dom";
import Loading from "../shared/loading/loading";

const HotNews = (data) => {
  const hotnewsData = data.hotnewsData;
  const [weatherInfoAU, setWeatherInfoAU] = useState();
  const [weatherInfoSL, setWeatherInfoSL] = useState();

  useEffect(() => {
    (async () => {
      await temp("Australia").then((res) => {
        setWeatherInfoAU(res);
      });
      await temp("Sri Lanka").then((res) => {
        setWeatherInfoSL(res);
      });
    })();
  }, []);

  function Day(date) {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  }

  return (
    <Container maxWidth="xl" className="hot-news-container">
      <Box pt={4}>
        <Grid item container direction="row" justifyContent="space-between">
          {hotnewsData !== undefined ? (
            <Grid item container md={7}>
              <Grid item container md={7}>
                <Link to={`/detailNews/?id=${hotnewsData._id}`}>
                  <img src={hotnewsData.media[0]} className="hotImg" />
                </Link>
              </Grid>

              <Grid item md={5} p={4}>
                <Link to={`/detailNews/?id=${hotnewsData._id}`}>
                  <h1>{hotnewsData.title}</h1>
                </Link>
                <div>
                  <h3 className="textWrap">
                    {DisplayHtml(hotnewsData.content)}
                  </h3>
                </div>

                {hotnewsData != undefined && (
                  <p>{timeCalculation(hotnewsData.publishedDateTime)}</p>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item container alignItem="center" md={7}>
              <Skeleton
                variant="rectangular"
                style={{ width: "40vw", height: "30vh" }}
              />
            </Grid>
          )}

          <Grid
            item
            md={5}
            container
            justifyContent={{ xs: "center", md: "flex-start" }}
            textAlign={{ xs: "center", md: "flex-start" }}
          >
            <Grid item className="weather-information" mb={2}>
              <h3> Weather Information</h3>

              <Grid item container>
                <Stack>
                  {weatherInfoSL !== null &&
                  typeof weatherInfoSL !== "undefined" ? (
                    <Grid item container>
                      <h4>
                        {weatherInfoSL.location.country} -{" "}
                        {Day(weatherInfoSL.location.localtime)}
                      </h4>
                      <span className="weather-icon">
                        <img src={weatherInfoSL.current.condition.icon} />
                      </span>
                      <h4 className="blue">
                        {weatherInfoSL.current.condition.text}&nbsp;
                        {weatherInfoSL.current.temp_c} 'C
                      </h4>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="center"
                      p={2}
                    >
                      <Loading />
                    </Grid>
                  )}
                  {weatherInfoAU !== null &&
                  typeof weatherInfoAU !== "undefined" ? (
                    <Grid item container>
                      <h4>
                        {weatherInfoAU.location.country} -{" "}
                        {Day(weatherInfoAU.location.localtime)}
                      </h4>
                      <span className="weather-icon">
                        <img src={weatherInfoAU.current.condition.icon} />
                      </span>
                      <h4 className="blue">
                        {weatherInfoAU.current.condition.text}&nbsp;
                        {weatherInfoAU.current.temp_c} 'C
                      </h4>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="center"
                      p={2}
                    >
                      <Loading />
                    </Grid>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Grid
              item
              container
              className="empty-content"
              justifyContent="center"
              alignItems="center"
            >
              {" "}
              Ads Area
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HotNews;
