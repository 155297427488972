import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import galleryimg from "../../assets/images/pop9.jpg";
import galleryimg2 from "../../assets/images/pop91.jpg";
import "./gallerry.css";
import {
  gal1,
  gal2,
  gal3,
  gal4,
  gal5,
  gal6,
} from "../../services/news/news.service";
import { getGalleyImages } from "../../services/media.services";
import { Skeleton } from "@mui/material";

export default function Gallery() {
  const [galView1, setGalView1] = useState([]);
  const [galView2, setGalView2] = useState([]);
  const [galView3, setGalView3] = useState([]);
  const [galView4, setGalView4] = useState([]);
  const [galView5, setGalView5] = useState([]);
  const [galView6, setGalView6] = useState([]);

  useEffect(() => {
    async function getGalleryImages() {
      await getGalleyImages().then((res) => {
        setGalView1([res[0]]);
        setGalView2([res[1]]);
        setGalView3([res[2]]);
        setGalView4([res[3]]);
        setGalView5([res[4]]);
        setGalView6([res[5]]);
      });
    }
    getGalleryImages();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container alignSelf="flex-start" mb={1} spacing={2}>
        {galView1.map((item, index) => {
          return (
            <Grid key={index} item xs={12}>
              {item.media[0] ? (
                <img src={item.media[0]} className="galleryimg" />
              ) : (
                <Skeleton variant="rectangular" width={150} height={118} />
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid container mb={1} spacing={2}>
        {galView2.map((item, index) => {
          return (
            <Grid key={index} item xs={4}>
              {item.media[0] ? (
                <img src={item.media[0]} className="galleryimg" />
              ) : (
                <Skeleton variant="rectangular" width={150} height={118} />
              )}
            </Grid>
          );
        })}
        {galView3.map((item, index) => {
          return (
            <Grid key={index} item xs={4}>
              {item.media[0] ? (
                <img src={item.media[0]} className="galleryimg" />
              ) : (
                <Skeleton variant="rectangular" width={150} height={118} />
              )}
            </Grid>
          );
        })}
        {galView4.map((item, index) => {
          return (
            <Grid key={index} item xs={4}>
              {item.media[0] ? (
                <img src={item.media[0]} className="galleryimg" />
              ) : (
                <Skeleton variant="rectangular" width={150} height={118} />
              )}
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={2}>
        {galView5.map((item, index) => {
          return (
            <Grid key={index} item xs={8}>
              {item.media[0] ? (
                <img src={item.media[0]} className="galleryimg" />
              ) : (
                <Skeleton variant="rectangular" width={150} height={118} />
              )}
            </Grid>
          );
        })}
        {galView6.map((item, index) => {
          return (
            <Grid key={index} item xs={4}>
              {item.media[0] ? (
                <img src={item.media[0]} className="galleryimg" />
              ) : (
                <Skeleton variant="rectangular" width={150} height={118} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
