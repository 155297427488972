import { Grid } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import authServices from "../../../services/auth.services";
import { getReactions, postReact } from "../../../services/reactions.services";
import "./Reaction.css";

const Reaction = (data) => {
  const [newsId, setNewsId] = useState(data.newsId);
  const [reactions, setReactions] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleReactionClick = (reactionId) => {
    postReact(searchParams.get("id"), reactionId).then((res) => {
      toast.success("Reacted", {
        position: "top-center",
        autoClose: 1000,
        pauseOnHover: false,
      });
      const newReactions = [...reactions];
      newReactions.forEach((reaction) => {
        res.data.reactionCount.forEach((updatedReaction) => {
          if (reaction._id === updatedReaction._id) {
            reaction.count = updatedReaction.count;
          }
        });
      });
      setReactions(newReactions);
    });
  };

  useEffect(() => {
    setNewsId(searchParams.get("id"));
    getReaction();
  }, [searchParams]);

  async function getReaction() {
    await getReactions(searchParams.get("id")).then((res) => {
      setReactions(
        res.data.map((reaction) => ({
          emoji: reaction.react,
          count: reaction.count,
          id: reaction._id,
        }))
      );
    });
  }

  return (
    <div className="ReactPanel">
      <Grid
        container
        style={{ display: "flex", flexDirection: "row" }}
        spacing={2}
      >
        {reactions.map((reaction, index) => (
          <Grid item key={index}>
            <div
              className="emojiList"
              onClick={() => handleReactionClick(reaction.id)}
            >
              <div className="emoji"> {reaction.emoji}</div>
              <div className="count"> {reaction.count}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Reaction;
