import { Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "./hot-news-tag-line.css";
import { hotNewsView } from "../../services/news/news.service";
import { Link } from "react-router-dom";
import Loading from "../shared/loading/loading";

const HotNewsTagLine = (data) => {
  const [hotNews, setHotNews] = useState([]);

  useEffect(() => {
    fetchTagLines();
  }, []);

  async function fetchTagLines() {
    const responseData = await hotNewsView();
    setHotNews(responseData.data);
  }

  const properties = {
    prevArrow: <button style={{ display: "none" }}></button>,
    nextArrow: <button style={{ display: "none" }}></button>,
  };
  const responsiveSettings = [
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
      },
    },
  ];

  return (
    <div className="hotNewsTagsSection">
      <Box sx={{ flexGlow: 1 }}>
        <Container maxWidth="xl">
          <Grid container alignItems="center">
            <Grid item md={2} className="red-tag-dark-bg">
              <h3>උණුසුම් පුවත්</h3>
            </Grid>
            <Grid item md={10} className="red-tag-light-bg">
              {hotNews.length > 0 ? (
                <Slide
                  {...properties}
                  slidesToScroll={1}
                  responsive={responsiveSettings}
                >
                  {hotNews.map((item, index) => {
                    return (
                      <div key={index} className="each-slide-effect">
                        <h3>
                          <Link to={`/detailNews/?id=${item._id}`}>
                            {item.title}
                          </Link>
                        </h3>
                      </div>
                    );
                  })}
                </Slide>
              ) : (
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItem="center"
                  p={1}
                >
                  <Loading />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default HotNewsTagLine;
