import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "./signin.css";
import logo from "../../assets/images/Logo-Official.png";
import { Button, Container, Grid } from "@mui/material";
import { BsGoogle } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/auth.services";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      try {
        let googleUser = await AuthService.getGoogleUser();
        toast.success("Welcome " + googleUser.filteredUser.firstName);
        localStorage.setItem("token", googleUser.token);
        localStorage.setItem("userId", googleUser.filteredUser.id);
        localStorage.setItem(
          "userFirstName",
          googleUser.filteredUser.firstName
        );
        googleUser = "";
        navigate(`/`);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is Required!"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const { values, errors, handleChange, handleSubmit, touched, setValues } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        // alert(JSON.stringify(values, null, 2));
        setLoading(true);
        try {
          AuthService.signIn(values)
            .then((res) => {
              if (res.data.error) {
                toast.error(res.data.error);
              }
              if (res.data.token) {
                toast.success("Welcome " + res.data.filteredUser.firstName);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("userId", res.data.filteredUser.id);
                localStorage.setItem(
                  "userFirstName",
                  res.data.filteredUser.firstName
                );
                navigate(`/`);
              }
              setLoading(false);
            })
            .catch((error) => {
              toast.error(error.message);
            });
        } catch (error) {
          toast.error(error.message);
        }
      },
    });

  return (
    <div className="Signin">
      <Grid container justifyContent="center" pt={{ xs: 2, md: 8 }}>
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </Grid>
              </Grid>
              <Grid item container justifyContent="center" alignItems="center">
                <p className="signintag">Sign In With</p>
              </Grid>
              <Grid
                item
                container
                spacing={4}
                pb={4}
                justifyContent="center"
                alignItems="center"
                className="social-login-btn"
              >
                <Grid item md={6}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      className="gbtn"
                      onClick={AuthService.google}
                      style={{
                        borderRadius: "12px",
                        borderColor: "#C4C4C4",
                        color: "black",
                      }}
                    >
                      <BsGoogle className="social-login-icon" />
                      <p className="btntext">Google</p>
                    </Button>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      className="fbtn"
                      onClick={AuthService.facebook}
                      style={{
                        borderRadius: "12px",
                        borderColor: "#C4C4C4",
                        color: "black",
                      }}
                    >
                      <FaFacebookF className="social-login-icon" />{" "}
                      <p className="btntext">Facebook</p>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container justifyContent="center" spacing={2}>
                <Grid item md={5}>
                  <hr />
                </Grid>
                <Grid item md={2}>
                  OR
                </Grid>
                <Grid item md={5}>
                  <hr />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="input-full-width"
              >
                <Grid item container>
                  <h4>Email</h4>
                </Grid>
                <Grid item container>
                  <TextField
                    sx={{
                      width: "100%",
                      height: 43,
                    }}
                    label="Email"
                    variant="outlined"
                    type="text"
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                    value={values.email}
                    onChange={handleChange("email")}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item container>
                  <h4>Password</h4>
                </Grid>
                <Grid item container>
                  <TextField
                    sx={{ width: "100%", height: "43px" }}
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      style: { borderRadius: "15px" },
                      endAdornment: (
                        <>
                          {showPassword ? (
                            <AiFillEye
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <AiFillEyeInvisible
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </>
                      ),
                    }}
                    value={values.password}
                    onChange={handleChange("password")}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <Link to="/forgetPassword">
                    <p className="FP">Forgot Password?</p>
                  </Link>
                </Grid>
              </Grid>
              <Grid item container>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  className="SignInbtn"
                  type="Submit"
                >
                  <p className="SignBtnText">Sign In</p>
                </LoadingButton>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                className="Accountrequest"
              >
                <p>
                  Don't have an account?
                  <Link to="/signup">
                    <b className="Signuptag"> Sign Up</b>
                  </Link>
                </p>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Grid>
    </div>
  );
};

export default Signin;
