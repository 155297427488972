import axios from "axios";

const API_KEY = "a90c2a434afd412ab23b200ba776b970";
const API_URL = `https://openexchangerates.org/api/latest.json?app_id=${API_KEY}`;

const convertCurrency = async (amount, fromCurrency, toCurrency) => {
  try {
    const { data } = await axios.get(API_URL);
    const rate = data.rates[toCurrency] / data.rates[fromCurrency];
    const convertedAmount = amount * rate;
    return convertedAmount;
  } catch (error) {
    console.error(error);
  }
};

export default convertCurrency;
