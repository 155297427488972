import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import "./categoryView.css";
import { Container } from "@mui/material";
import alsoLikeImg from "../../assets/images/landscape-morning-fog-mountains-with-hot-air-balloons-sunrise_335224-794.png";
import { fetchCatData } from "../../services/news/category_view.service";
import { fetchCatNewsData } from "../../services/news/category_view.service";
import htmlTagsView from "../../services/htmlTagsView";
import Skeleton from "@mui/material/Skeleton";
import Header from "../../components/header/header";
import TopicSection from "../../components/topic-section/topic-section";
import HotNewsTagLine from "../../components/hot-news-tag-line/hot-news-tag-line";
import Footer from "../../components/footer/footer";
import { InsertEmoticon } from "@mui/icons-material";
import { getSortedCategoryNews } from "../../services/news/news.service";
import commonVar from "../../services/common";
import {
  Link,
  Navigate,
  ScrollRestoration,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { timeCalculation } from "../../services/shared.services";
import Loading from "../../components/shared/loading/loading";

function CategoryView() {
  const [id, setId] = useState();
  const [topCatNews, setTopCatNews] = useState();
  const [otherCatNews, setOtherCatNews] = useState();
  const [recentNews, setRecentNews] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get("id")) navigate("/");
    setId(searchParams.get("id"));
    fetchNewsData();
    fetchRecentNewsData();
  }, [searchParams]);

  async function fetchNewsData() {
    setLoading(true);
    await fetchCatData(searchParams.get("id")).then((res) => {
      setLoading(false);
      // setTopCatNews(res[0]);
      // console.log(res);
      // setOtherCatNews(res.splice(1, res.length));
      if (res[0]) {
        setTopCatNews(res[0]);
        setOtherCatNews(res.splice(1, res.length));
      } else {
        navigate("/");
      }
    });
  }
  async function fetchRecentNewsData() {
    setLoading(true);
    await getSortedCategoryNews().then((res) => {
      setLoading(false);
      setRecentNews(res.data.recent);
      if (res) {
        setRecentNews(res.data.recent);
      } else {
        navigate("/");
      }
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="categoryScreen">
      <Container maxWidth="xl" className="hot-news-container">
        <Box py={4}>
          {topCatNews != undefined ? (
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              xs={12}
              spacing={2}
            >
              <Grid item container md={12}>
                <h1>{topCatNews.categoryId.category}</h1>
              </Grid>

              <Grid item container md={5}>
                <Grid item container>
                  <Link to={`/detailNews/?id=${topCatNews._id}`}>
                    <img
                      src={topCatNews.media[0]}
                      className="categoryViewimg"
                    />
                  </Link>
                </Grid>
              </Grid>
              <Grid item container md={3}>
                <Grid item container textAlign={{ xs: "center", md: "left" }}>
                  <Link to={`/detailNews/?id=${topCatNews._id}`}>
                    <h1>{topCatNews.title}</h1>
                  </Link>

                  <h3 className="textWrap">
                    {htmlTagsView(topCatNews.content)}
                  </h3>
                  <p>{timeCalculation(topCatNews.publishedDateTime)}</p>
                </Grid>
              </Grid>

              <Grid
                item
                md={4}
                container
                justifyContent={{ xs: "center", md: "flex-start" }}
                textAlign={{ xs: "center", md: "flex-start" }}
              >
                <Grid item className="adAreaView" mb={2}></Grid>

                <Grid
                  item
                  container
                  className="empty-content"
                  justifyContent="center"
                  alignItems="center"
                ></Grid>
              </Grid>
            </Grid>
          ) : (
            <Skeleton variant="rectangular" className="Skeleton-img" />
          )}

          <Grid container>
            <h1>අනෙකුත් පුවත්</h1>
          </Grid>
          <Grid item container>
            {otherCatNews != undefined ? (
              <Grid container py={2} spacing={2}>
                {otherCatNews.map((item, index) => {
                  if (
                    index >
                    commonVar.SCREEN_CATEGORY_NEWS_COUNT -
                      (commonVar.SCREEN_CATEGORY_OTHER_NEWS_COUNT + 1)
                  )
                    return;
                  return (
                    <Grid
                      key={index}
                      item
                      container
                      md={6}
                      xs={12}
                      spacing={2}
                      alignItems="center"
                      justifyContent={{ md: "center", xs: "flex-start" }}
                    >
                      <Grid container item md={6} className="categoryViewimg">
                        <Link to={`/detailNews/?id=${item._id}`}>
                          <img
                            src={item.media[0]}
                            className="categoryViewimg"
                          />
                        </Link>
                      </Grid>
                      <Grid item md={6}>
                        <Link to={`/detailNews/?id=${item._id}`}>
                          <h2>{item.title}</h2>
                        </Link>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container item>
                <Skeleton variant="rectangular" className="Skeleton-img" />
              </Grid>
            )}
            {otherCatNews != undefined ? (
              <Grid item container md={6} spacing={2}>
                {otherCatNews.map((item, index) => {
                  if (index > commonVar.SCREEN_CATEGORY_NEWS_COUNT) return;
                  if (
                    index >
                    commonVar.SCREEN_CATEGORY_NEWS_COUNT -
                      (commonVar.SCREEN_CATEGORY_OTHER_NEWS_COUNT + 1)
                  )
                    return (
                      <Grid key={index} item container spacing={2}>
                        <Grid item xs={6}>
                          <Link to={`/detailNews/?id=${item._id}`}>
                            <img
                              src={item.media[0]}
                              className="categoryViewimg"
                            />
                          </Link>
                        </Grid>
                        <Grid item xs={6}>
                          <Link to={`/detailNews/?id=${item._id}`}>
                            <h2>{item.title}</h2>
                          </Link>
                        </Grid>
                      </Grid>
                    );
                })}
              </Grid>
            ) : (
              <Grid container item>
                <Skeleton variant="rectangular" className="Skeleton-img" />
              </Grid>
            )}
            {recentNews != undefined && (
              <Grid item container md={6} spacing={2} p={2}>
                <Grid item container>
                  <h1>වෙනත් පුවත්</h1>
                </Grid>
                {recentNews.map((item, index) => {
                  if (index > 1) return;
                  return (
                    <Grid key={index} item container spacing={2}>
                      <Grid item container md={6}>
                        <Link to={`/detailNews/?id=${item._id}`}>
                          <img
                            src={item.media[0]}
                            className="categoryViewimg"
                          />
                        </Link>
                      </Grid>
                      <Grid item container md={6}>
                        <Link to={`/detailNews/?id=${item._id}`}>
                          <h2>{item.title}</h2>
                        </Link>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default CategoryView;
