const commonVar = {
  baseUrl: "http://52.221.121.249:8088/api",
  SportNewsId: "63d89b88a2cb54ad6ccbd6d6",
  BusinessNewsId: "63d89b99a2cb54ad6ccbd6d9",
  PoliticalNewsId: "63d89bd2a2cb54ad6ccbd6dc",
  WorldNewsId: "63d89be0a2cb54ad6ccbd6df",
  EnvirenmentNewsId: "63d8a2b2a2cb54ad6ccbd74c",
  CartoonNewsId: "63d89c3aa2cb54ad6ccbd6f1",
  NewItemsNewsId: "63d89beca2cb54ad6ccbd6e2",
  GossipNewsId: "63d89c0ea2cb54ad6ccbd6e8",
  HotNewsId: "63d8bdf8a2cb54ad6ccbd8b5",
  SCREEN_CATEGORY_NEWS_COUNT: 4,
  SCREEN_CATEGORY_OTHER_NEWS_COUNT: 2,
};
export default commonVar;

// Backend Local:   baseUrl: "http://localhost:8088/api",
// Backend Staging:  baseUrl: "http://52.221.121.249:8088/api",
// Backend Live:    baseUrl: "https://newsz.lk/api",
