import { Grid, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./top-news.css";
import { timeCalculation } from "../../services/shared.services";
import { Link } from "react-router-dom";
import classNames from "classnames";

const TopNews = (data) => {
  const newsDataArr = data.newsData;
  const newsData = newsDataArr.slice(2, 10);
  const topImageClass = classNames({
    topnewsimg: true,
    "topnewsimg-mob": true,
    "topnewsimg-mobpad": true,
  });
  return (
    <div>
      {newsData.length > 0 ? (
        <Grid container py={2} spacing={2}>
          {newsData.map((item, index) => {
            return (
              <Grid key={index} item container md={6}>
                <Grid item md={6} pr={{ xs: 0, md: 4 }}>
                  <Link to={`/detailNews/?id=${item._id}`}>
                    <img
                      src={item.media[0]}
                      className={topImageClass}
                      alt="news-image"
                    />
                  </Link>
                </Grid>
                <Grid
                  item
                  md={6}
                  px={2}
                  textAlign={{ xs: "center", md: "left" }}
                >
                  <Link to={`/detailNews/?id=${item._id}`}>
                    <h1>{item.title}</h1>
                  </Link>

                  <p>{timeCalculation(item.publishedDateTime)}</p>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center" pt={2}>
          <Skeleton
            variant="rectangular"
            style={{ width: "90vw", height: "30vh" }}
          />
        </Grid>
      )}
    </div>
  );
};

export default TopNews;
