import { Box } from "@mui/system";
import React from "react";
import "./emailverify.css";
import logo from "../../assets/images/Logo-Official.png";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
const emailverify = () => {
  return (
    <div className="Signin">
      <Grid container justifyContent="center" pt={{ xs: 2, md: 8 }}>
        <Card sx={{ maxWidth: "100vw" }} md={{ maxWidth: "100vw" }}>
          <CardContent>
            <Box sx={{ flexGrow: 1 }} p={4}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </Grid>
              </Grid>
              <Grid item container justifyContent="center" alignItems="center">
                <p className="emailveritag">Email Verification</p>
              </Grid>
              <Grid item container justifyContent="center" alignItems="center">
                <p className="vericode">Please Enter your verification code</p>
              </Grid>

              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="input-full-width"
              >
                <Grid item container>
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    placeholder="Ex - 123456"
                    variant="outlined"
                    type="Name"
                    InputProps={{
                      style: { borderRadius: "15px" },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item container className="SignInButton" marginTop="20px">
                <Button
                  variant="contained"
                  className="SignInbtn"
                  style={{
                    backgroundColor: "#CC0526",
                    color: "white",
                    borderRadius: "12px",
                    height: "50px",
                  }}
                >
                  <p className="SignBtnText">Sign In</p>{" "}
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default emailverify;
