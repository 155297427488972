import React from "react";
import axios from "axios";
import commonVar from "../common";

const baseURL = commonVar.baseUrl;

export function testApi() {
  return axios
    .get(baseURL + "/api-test")
    .then((response) => {
      response = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function fetchSearchNews(title) {
  return await axios
    .get(baseURL + "/news/search-by-title", { params: { title } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getSortedCategoryNews() {
  return await axios
    .get(baseURL + "/news/filer-news-by-category")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getCategoryNews(id) {
  return await axios
    .get(baseURL + `/news/category-news/:${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function viewCategory() {
  return await axios
    .get(baseURL + "/news-category/view-news-category")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function viewANews() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ee89a2cb54ad6ccbded4")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function headNews() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8bbeaa2cb54ad6ccbd895")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function hotNews() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8bf31a2cb54ad6ccbd8ba")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function specialNews1() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ab1aa2cb54ad6ccbd77b")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function specialNews2() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ad41a2cb54ad6ccbd793")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function viewCategoryHome1() {
  return await axios
    .get(baseURL + "/news-category/view-a-news-category/::id")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function environmentNews1() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a33aa2cb54ad6ccbd751")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function environmentNews2() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a3aaa2cb54ad6ccbd755")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function environmentNews3() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a443a2cb54ad6ccbd759")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function environmentNews4() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a4d3a2cb54ad6ccbd75d")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function hotNewsView() {
  return await axios
    .get(baseURL + "/news/view-news")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function catNews() {
  return await axios
    .get(baseURL + "/news/view-news")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topNews() {
  return await axios
    .get(baseURL + "/news/view-news")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topnews1() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8aefea2cb54ad6ccbd7a3")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topnews2() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ad8da2cb54ad6ccbd797")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topnews3() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ad41a2cb54ad6ccbd793")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topnews4() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8acc7a2cb54ad6ccbd78f")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topnews5() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ac82a2cb54ad6ccbd78b")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topnews6() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ac42a2cb54ad6ccbd787")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topnews7() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ac0aa2cb54ad6ccbd783")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function topnews8() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8ab69a2cb54ad6ccbd77f")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function sportnews1() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d89f7aa2cb54ad6ccbd730")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function sportnews2() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a56da2cb54ad6ccbd761")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function businessnews1() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a004a2cb54ad6ccbd734")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function businessnews2() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a075a2cb54ad6ccbd738")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function politicnews1() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a158a2cb54ad6ccbd740")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function politicnews2() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a0faa2cb54ad6ccbd73c")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function worldnews1() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a1eba2cb54ad6ccbd744")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function worldnews2() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8a279a2cb54ad6ccbd748")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function cartoon() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8aeb5a2cb54ad6ccbd79f")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function gal1() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8af2ca2cb54ad6ccbd7a7")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function gal2() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8af47a2cb54ad6ccbd7ab")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function gal3() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8af7fa2cb54ad6ccbd7af")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function gal4() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8b001a2cb54ad6ccbd7b3")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function gal5() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8b02aa2cb54ad6ccbd7b7")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function gal6() {
  return await axios
    .get(baseURL + "/news/view-a-news/:63d8af2ca2cb54ad6ccbd7a7")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
