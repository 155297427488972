import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Textarea from "@mui/joy/Textarea";
import { AiFillHeart } from "react-icons/ai";
import "./comment.js";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import {
  createComment,
  getNewsComment,
} from "../../../services/comments.services";
import { ToastContainer, toast } from "react-toastify";
import Reaction from "../reaction-panel/Reaction.js";
import { getTotalReact } from "../../../services/reactions.services.js";

const Comment = (data) => {
  const [newsId, setNewsId] = useState(data.newsId);
  const [commentText, setCommentText] = useState();
  const [commentsCount, setCommentsCount] = useState(data.commentCount);
  const [reactCount, setReactCount] = useState(data.reactCount);
  useEffect(() => {
    setNewsId(data.newsId);
    setReactCount(data.reactCount);
    setCommentsCount(data.commentCount);
  }, [data]);

  const handleTextAreaChange = (e) => {
    if (e.target.value.length <= 500) {
      setCommentText(e.target.value);
    } else {
      toast.warn("Charactor Limit Exceeded!!!");
    }
  };

  async function submitComment() {
    if (commentText === "" || commentText == undefined) {
      toast.warn("comment is empty");
    } else {
      await createComment(newsId, commentText).then((res) => {
        if (res) {
          setCommentText("");
          return toast.success("Comment Posted");
        }
      });
    }
  }
  return (
    <Grid container direction="row" p={2}>
      <Grid item container>
        <Grid item container justifyContent="flex-end" spacing={2}>
          <Grid item>{reactCount} Reacts</Grid>
          <Grid item>{commentsCount} Comments</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} py={1}>
        <Textarea
          color="primary"
          minRows={4}
          size="lg"
          value={commentText}
          onChange={handleTextAreaChange}
        />
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Button variant="outlined" onClick={() => submitComment()}>
          Post Comment
        </Button>
      </Grid>
    </Grid>
  );
};

export default Comment;
