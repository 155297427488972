import React, { useState, useEffect } from "react";
import "./topic-section.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Container } from "@mui/system";
import axios from "axios";

import HomeIcon from "@mui/icons-material/Home";
import { Box, Grid } from "@mui/material";
import { viewCategory } from "../../services/news/news.service";
import { Link } from "react-router-dom";
import Loading from "../shared/loading/loading";

const TopicSection = (data) => {
  const [newsCategory, setNewsCategory] = useState([]);

  useEffect(() => {
    (async () => {
      const responseData = await viewCategory();
      setNewsCategory(responseData.data);
    })();
  }, []);

  return (
    <Box pt={2} pb={1}>
      <Container maxWidth="xl">
        <Grid container alignItems="center">
          <Grid item xs={1} className="hot-tags-home-icon">
            <Link to="/">
              <HomeIcon />
            </Link>
          </Grid>
          <Grid item xs={11}>
            {newsCategory.length > 0 ? (
              <Slide
                slidesToScroll={1}
                responsive={[
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToScroll: 1,
                      slidesToShow: 10,
                    },
                  },
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToScroll: 1,
                      slidesToShow: 1,
                    },
                  },
                ]}
              >
                {newsCategory.map((item, index) => {
                  return (
                    <div key={index} className="each-slide-effect">
                      <h3>
                        <Link to={"/categoryView/?id=" + item._id}>
                          {item.category}
                        </Link>
                      </h3>
                    </div>
                  );
                })}
              </Slide>
            ) : (
              <Grid
                item
                container
                justifyContent="center"
                alignItem="center"
                p={1}
              >
                <Loading />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item pt={2}>
          <div className="hot-tag-bottom-color-bar"></div>
        </Grid>
      </Container>
    </Box>
  );
};

export default TopicSection;
