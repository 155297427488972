import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import data from "../../../data/data";
import { fetchSearchNews } from "../../../services/news/news.service";
import commonVar from "../../../services/common";
import "./search.css";
const baseURL = commonVar.baseUrl;

const Search = () => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([0]);

  useEffect(() => {
    const fetchData = async () => {
      fetchSearchNews(inputValue).then((res) => {
        if (res.data) {
          setSuggestions(res.data);
        }
      });
    };
    fetchData();
  }, [inputValue]);

  return (
    <div className="searchCom">
      <Box>
        <Autocomplete
          freeSolo
          disableClearable
          options={suggestions.map((suggestion) => suggestion.title)}
          value={inputValue}
          onChange={(event, newValue) => {
            setInputValue(newValue);
            if (newValue) {
              const selectedSuggestion = suggestions.find(
                (suggestion) => suggestion.title === newValue
              );
              if (selectedSuggestion) {
                window.location.href = `/detailNews/?id=${selectedSuggestion._id}`;
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              onChange={(e) => setInputValue(e.target.value)}
              InputProps={{
                ...params.InputProps,
                type: "search",
                style: {
                  borderRadius: "10px",
                  fontSize: "15px",
                  padding: 0,
                },
              }}
            />
          )}
        />
      </Box>
    </div>
  );
};

export default Search;
