import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import AuthService from "../../services/auth.services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import logo from "../../assets/images/Logo-Official.png";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
const VerifyAccount = () => {
  const navigate = useNavigate();
  const [tokenValid, setTokenValid] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (!tokenValid) {
      setLoading(true);
      AuthService.accountVerifyToken(token).then((res) => {
        if (res.data.error) {
          // toast.error("Unauthorized");
        } else if (res.data.message) {
          setTokenValid(true);
          setLoading(false);
        }
      });
    }
  }, [tokenValid]);

  const initialValues = {
    confirmCode: "",
  };

  const validationSchema = Yup.object().shape({
    confirmCode: Yup.string()
      .length(4, "Verification code must be 4 digits long")
      .matches(/^\d{4}$/, "Verification code must be a number")
      .required("Verification code is required"),
  });

  const { values, errors, handleChange, handleSubmit, touched, setValues } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        // alert(JSON.stringify(values, null, 2));
        setLoading(true);
        try {
          AuthService.accountVerifyConfirm(values)
            .then((res) => {
              if (res && res.data) {
                if (res.data.error) {
                  toast.error(res.data.error);
                } else if (res.data.message) {
                  toast.success(res.data.message);
                  navigate("/signin");
                }
                setLoading(false);
              }
            })
            .catch((error) => {
              toast.error(error.message);
            });
        } catch (error) {
          toast.error(error.message);
        }
      },
    });

  return (
    <div className="VerifyAccountCom">
      {tokenValid ? (
        <Grid container justifyContent="center" pt={{ xs: 2, md: 8 }}>
          <Card>
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Box p={4}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <Link to="/">
                        <img src={logo} />
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <p>Verify Email</p>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <p className="resetlink">Enter your confirm code</p>
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="input-full-width"
                  >
                    <Grid item container>
                      <h4>Confirm Code</h4>
                    </Grid>

                    <Grid item container>
                      <TextField
                        sx={{
                          width: "100%",
                        }}
                        variant="outlined"
                        type="number"
                        InputProps={{
                          style: { borderRadius: "15px" },
                        }}
                        value={values.confirmCode}
                        onChange={handleChange("confirmCode")}
                        error={Boolean(
                          touched.confirmCode && errors.confirmCode
                        )}
                        helperText={touched.confirmCode && errors.confirmCode}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    className="RequestButton"
                    marginTop="20px"
                  >
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      className="reqbtn"
                      type="Submit"
                    >
                      <p className="reqBtnText">Verify</p>
                    </LoadingButton>
                  </Grid>
                </Box>
              </CardContent>
            </form>
          </Card>
        </Grid>
      ) : (
        <p>Unauthorized</p>
      )}
    </div>
  );
};

export default VerifyAccount;
