import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

const Loading = () => {
  return (
    <div className="Loading">
      <Grid container justifyContent="center" alignItems="center">
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </Grid>
    </div>
  );
};

export default Loading;
