import { Button, Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteComment,
  editComment,
  getNewsComment,
} from "../../../services/comments.services";
import "./CommentView.css";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const CommentView = (data) => {
  const [newsId, setNewsId] = useState();
  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userId, setUserId] = useState();
  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [editedComment, setEditedComment] = useState();
  const [editedIndex, setEditedIndex] = useState();

  useEffect(() => {
    setNewsId(searchParams.get("id"));
    fetchData();
    setUserId(localStorage.getItem("userId"));
  }, [newsId, currentPage, itemsPerPage, searchParams]);

  const fetchData = async () => {
    try {
      const response = await getNewsComment(
        searchParams.get("id"),
        currentPage,
        itemsPerPage
      );
      setComments(response.data.comments);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = async (comment) => {
    try {
      await deleteComment(comment).then((res) => {
        if (res) {
          toast.success("Comment deleted successfully");
          fetchData();
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEdit = async (comment, index) => {
    setEditedComment(comment.comment);
    setIsCommentEdit(true);
    setEditedIndex(index);
  };
  const handleEditComfirm = async (comment) => {
    if (editedComment == "") {
      toast.warn("Comment is Empty");
    } else {
      comment.comment = editedComment;
      try {
        await editComment(comment).then((res) => {
          if (res.success) {
            toast.success("Comment update successfully");
            setIsCommentEdit(false);
          }
          fetchData();
        });
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleClose = async () => {
    setIsCommentEdit(false);
  };

  function formateDate(date) {
    const DateFormat = new Date(date);
    const formattedDate = DateFormat.toLocaleString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    });
    return formattedDate;
  }

  return (
    <div>
      <Grid item container>
        <Grid item container>
          {comments.length > 0 && <h3>Comments</h3>}
        </Grid>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {comments.map((comment, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemText
                primary={
                  <Grid item container spacing={2}>
                    <Grid item> {comment.userFirstName}</Grid>

                    <Grid item>{formateDate(comment.time)}</Grid>
                  </Grid>
                }
                secondary={
                  <React.Fragment>
                    <Grid
                      item
                      container
                      alignItems="center"
                      className="comment-content"
                    >
                      {isCommentEdit && editedIndex === index ? (
                        <TextField
                          id="standard-basic"
                          label="Edit Comment"
                          variant="standard"
                          value={editedComment}
                          onChange={(e) => {
                            setEditedComment(e.target.value);
                          }}
                        />
                      ) : (
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {comment.comment}
                        </Typography>
                      )}

                      {comment.userId === userId && (
                        <div>
                          {isCommentEdit && editedIndex === index ? (
                            <Button onClick={() => handleEditComfirm(comment)}>
                              <CheckIcon />
                            </Button>
                          ) : (
                            <Button onClick={() => handleEdit(comment, index)}>
                              <EditIcon />
                            </Button>
                          )}

                          {!isCommentEdit && (
                            <Button onClick={() => handleDelete(comment)}>
                              <DeleteIcon />
                            </Button>
                          )}

                          {isCommentEdit && editedIndex === index && (
                            <Button onClick={() => handleClose()}>
                              <CloseIcon />
                            </Button>
                          )}
                        </div>
                      )}
                    </Grid>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>

      <Grid item container>
        {totalPages > 1 &&
          Array.from({ length: totalPages }, (_, index) => (
            <Button key={index + 1} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </Button>
          ))}
      </Grid>
    </div>
  );
};

export default CommentView;
