import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import "./Gossip.css";
import { Input } from "@mui/joy";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { viewANews } from "../../services/news/news.service";
import convertCurrency from "../../services/currency_conversion";
import DisplayHtml from "../../services/htmlTagsView";
import { Link } from "react-router-dom";
import { timeCalculation } from "../../services/shared.services";
import classNames from "classnames";
import { Skeleton } from "@mui/material";

const Gossip = (data) => {
  const [newsView, setNewsView] = useState([]);
  const [amount, setAmount] = useState([]);
  const [currency, setCurrency] = useState("LKR");
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [convertedCurrency, setConvertedCurrency] = useState("USD");
  const gossipImageClass = classNames({
    gossipImg: true,
    "gossipImg-mob": true,
    "gossipImg-mobpad": true,
  });

  function handleAmountChange(e) {
    setAmount(e.target.value);
    convertCurrency(e.target.value, currency, convertedCurrency).then((res) => {
      setConvertedAmount(res);
    });
  }

  function handleCurrencyChange(e) {
    setCurrency(e.target.value);
  }

  function handleConvertedCurrencyChange(e) {
    setConvertedCurrency(e.target.value);
    if (e.target.value === currency) {
      setCurrency("");
    }
  }

  useEffect(() => {
    (async () => {
      const responseData = await viewANews();
      setNewsView(responseData.data);
    })();
  }, []);
  const gossipData = data.gossipData[0];

  return (
    <div className="GossipSection">
      <Box sx={{ flexGrow: 1 }}>
        {gossipData != undefined ? (
          <Grid container spacing={2}>
            <Grid item container md={7} spacing={2}>
              <Grid item md={7}>
                <Grid item>
                  <Link to={`/detailNews/?id=${gossipData._id}`}>
                    {gossipData.media[0] ? (
                      <img
                        src={gossipData.media[0]}
                        className={gossipImageClass}
                      />
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        width={350}
                        height={200}
                      />
                    )}
                  </Link>
                </Grid>
              </Grid>
              <Grid item md={5}>
                <Grid item>
                  <Link to={`/detailNews/?id=${gossipData._id}`}>
                    <h1>{gossipData.title} </h1>
                  </Link>
                </Grid>
                <Grid item className="text-content">
                  <h3>{DisplayHtml(gossipData.content)} </h3>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {gossipData != undefined ? (
                    <p>{timeCalculation(gossipData.publishedDateTime)}</p>
                  ) : (
                    <Skeleton variant="rectangular" width={210} height={118} />
                  )}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    component={Link}
                    to={"/detailNews/?id=" + gossipData._id}
                    className="see-more-btn"
                    variant="outlined"
                    style={{ backgroundColor: "#F2F2F2", borderRadius: "25px" }}
                  >
                    See more
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={5}>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item container>
                  <p className="para1">Convert </p>
                  <br />
                </Grid>
                <Grid item container>
                  <p className="para2">Your Currency</p>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                className="grey-box-bg"
                my={2}
              >
                <Grid item xs={5}>
                  <Input
                    type="number"
                    placeholder="Enter Your Currency Value"
                    value={amount}
                    onChange={handleAmountChange}
                    className="inputvalue"
                  />
                </Grid>
                <Grid item xs={2}>
                  <h3 className="breaker">|</h3>
                </Grid>
                <Grid item xs={5} textAlign="left">
                  <select
                    className="selectcurrency"
                    value={currency}
                    onChange={handleCurrencyChange}
                  >
                    <option className="selectText" value="LKR">
                      Sri Lankan Rupees (LKR)
                    </option>
                    <option className="selectText" value="AUD">
                      Australian Dollar (AUD)
                    </option>
                  </select>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                className="grey-box-bg"
                my={2}
              >
                <Grid item xs={5}>
                  <Input
                    type="string"
                    placeholder="USD amount"
                    value={convertedAmount}
                    onChange={(event) => setConvertedAmount(event.target.value)}
                    className="inputvalue"
                  />
                </Grid>
                <Grid item xs={2}>
                  <h3 className="breaker">|</h3>
                </Grid>
                <Grid item xs={5} textAlign="left">
                  <select
                    className="selectcurrency"
                    value={convertedCurrency}
                    onChange={handleConvertedCurrencyChange}
                  >
                    <option value="USD">United State Dollar</option>
                    <option value="AUD">Australian Dollar</option>
                    <option value="LKR">Sri Lankan Rupee</option>
                  </select>
                </Grid>
              </Grid>
              {/* <Button onClick={convert}>Convert</Button> */}
            </Grid>
          </Grid>
        ) : (
          <Skeleton variant="rectangular" width={350} height={200} />
        )}
      </Box>
    </div>
  );
};

export default Gossip;
