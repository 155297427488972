import { Grid, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "./Special.css";
import { specialNews1, specialNews2 } from "../../services/news/news.service";
import { timeCalculation } from "../../services/shared.services";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Special = (data) => {
  const specialDataArr = data.specialData;
  const specialData = specialDataArr.slice(0, 2);
  const spImageClass = classNames({
    specialimg: true,
    "specialimg-mob": true,
  });
  return (
    <div className="SpecialNewsSection">
      {specialData != undefined ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {specialData.map((item, index) => {
              return (
                <Grid item container key={index} spacing={2}>
                  <Grid item md={6}>
                    <Link to={`/detailNews/?id=${item._id}`}>
                      <img src={item.media[0]} className={spImageClass} />
                    </Link>
                  </Grid>
                  <Grid item md={6}>
                    <Grid item container>
                      <Link to={`/detailNews/?id=${item._id}`}>
                        <h2>{item.title}</h2>
                      </Link>
                    </Grid>
                    <Grid item container>
                      <p>{timeCalculation(item.publishedDateTime)}</p>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Skeleton
          variant="rectangular"
          className="skeleton"
          style={{ height: "30vh" }}
        />
      )}
    </div>
  );
};

export default Special;
