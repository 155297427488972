import { Grid, Skeleton } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import "./head-news-section.css";
import subscriptionBanner from "../../assets/images/Mask_group.png";
import { headNews } from "../../services/news/news.service";
import { timeCalculation } from "../../services/shared.services";
import { Link } from "react-router-dom";
import Loading from "../shared/loading/loading";

const HeadNewsSection = (data) => {
  const [headNewsView, setHeadNewsView] = useState([]);
  const [recentNews, setRecentNews] = useState();
  useEffect(() => {
    setRecentNews(data.recentFirstNews);
  }, [data]);
  return (
    <Box py={4} sx={{ flexGrow: 1 }}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid
            item
            container
            sm={4}
            className="head-news-section-img"
            justifyContent="flex-start"
            alignItems="center"
          >
            {recentNews ? (
              <Link to={`/detailNews/?id=${recentNews._id}`}>
                <img src={recentNews.media[0]} />
              </Link>
            ) : (
              <Grid item container alignItem="center" md={7}>
                <Skeleton
                  variant="rectangular"
                  style={{ width: "40vw", height: "30vh" }}
                />
              </Grid>
            )}
          </Grid>

          <Grid
            item
            container
            sm={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item container className="headling-text">
              {recentNews ? (
                <Link to={`/detailNews/?id=${recentNews._id}`}>
                  <h1>{recentNews.title}</h1>
                </Link>
              ) : (
                <Grid item container justifyContent="center" alignItem="center">
                  <Loading />
                </Grid>
              )}
              {recentNews ? (
                <p>{timeCalculation(recentNews.publishedDateTime)}</p>
              ) : (
                <Grid item container justifyContent="center" alignItem="center">
                  <Loading />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item container sm={4} spacing={1}>
            <Grid
              item
              container
              justifyContent="flex-start"
              className="red-line"
            >
              <h3>නරඹන්න</h3>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className="subscribe-banner"
            >
              <img src={subscriptionBanner} />
            </Grid>
          </Grid>
          <Grid item container sm={2} xs={12} alignItems="stretch">
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className="grey-box-ads-headnews"
            >
              Ads Area
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeadNewsSection;
