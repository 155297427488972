import axios from "axios";
import { toast } from "react-toastify";
import commonVar from "./common";

const baseURL = commonVar.baseUrl;

const signIn = async (data) => {
  try {
    const response = await axios.post(baseURL + "/users/login", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const signUp = async (data) => {
  try {
    const response = await axios.post(baseURL + "/users/register", data);
    return response.data;
  } catch (error) {
    toast.error(error);
  }
};

const accountVerifyToken = async (token) => {
  try {
    const response = await axios.get(
      baseURL + `/users/account-verify-token/:${token}`
    );
    return response.data;
  } catch (error) {
    toast.error(error);
  }
};

const accountVerifyConfirm = async (data) => {
  const confirmCode = data.confirmCode;
  try {
    const response = await axios.get(
      baseURL + `/users/account-verify-confirm/:${confirmCode}`
    );
    return response.data;
  } catch (error) {
    toast.error(error);
  }
};

const resetPassword = async (data) => {
  try {
    const response = await axios.post(baseURL + "/users/reset-password", data);
    return response.data;
  } catch (error) {
    toast.error(error);
  }
};

const resetPasswordConfirm = async (id) => {
  try {
    const response = await axios.get(
      baseURL + `/users/reset-password-verify/:${id}`
    );
    return response.data;
  } catch (error) {
    toast.error(error);
  }
};
const setNewPassword = async (data, email) => {
  data.email = email;
  try {
    const response = await axios.post(
      baseURL + `/users/set-new-password/`,
      data
    );
    return response.data;
  } catch (error) {
    toast.error(error);
  }
};

const signOut = async () => {
  localStorage.clear();
  signOutSocial();
};

const google = async () => {
  window.open(baseURL + "/auth/google", "_self");
};

const googleSignup = async () => {
  window.open(baseURL + "/auth/google/signup", "_self");
};

const facebook = async () => {
  window.open(baseURL + "/auth/facebook", "_self");
};

const signOutSocial = async () => {
  window.open(baseURL + "/auth/logout", "_self");
};

const getGoogleUser = async () => {
  try {
    const response = await axios.get(baseURL + "/auth/login/success", {
      withCredentials: true,
    });
    console.log(response);
    if (response.status === 200 && response.data.token) {
      return response.data;
    } else {
      throw new Error("Authentication failed");
    }
  } catch (error) {
    toast.error(error);
  }
};

export default {
  google,
  googleSignup,
  facebook,
  signUp,
  signIn,
  signOut,
  resetPassword,
  resetPasswordConfirm,
  setNewPassword,
  accountVerifyToken,
  accountVerifyConfirm,
  getGoogleUser,
};
