import React from "react";
import axios from "axios";
import commonVar from "./common";
import { toast } from "react-toastify";

const baseURL = commonVar.baseUrl;

export async function createComment(newsId, commentText) {
  const token = localStorage.getItem("token");
  const updated_at = new Date();

  return axios
    .post(
      baseURL + `/comment/new-comment`,
      {
        comment: commentText,
        updated_at: updated_at,
        created_at: updated_at,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { newsId },
      }
    )
    .then((response) => {
      return (response = response.data.data);
    })
    .catch((error) => {
      if (error.response.status == 403) {
        localStorage.clear();
        toast.error("Please Login");
        window.location.href = "/signin";
      }
    });
}

export async function getNewsComment(newsId, currentPage, itemsPerPage) {
  return await axios
    .get(`${baseURL}/comment/view-news-comments/`, {
      params: { newsId, currentPage, itemsPerPage },
    })
    .then((response) => {
      return (response = response.data.data);
    })
    .catch((error) => {
      // console.log(error);
    });
}

export async function deleteComment(comment) {
  const { commentId } = comment;
  return await axios
    .delete(`${baseURL}/comment/delete-comment`, {
      params: { commentId },
    })
    .then((response) => {
      return (response = response.data.data);
    })
    .catch((error) => {
      // console.log(error);
    });
}

export async function editComment(data) {
  const { commentId, comment } = data;
  const updated_at = new Date();

  return await axios
    .post(`${baseURL}/comment/update-comment`, {
      commentId: commentId,
      comment: comment,
      updated_at: updated_at,
    })
    .then((response) => {
      return (response = response.data.data);
    })
    .catch((error) => {
      // console.log(error);
    });
}
