import { Box } from "@mui/system";
import React from "react";
import "./share-links.css";
import { BsFacebook, BsTelegram, BsTwitter, BsWhatsapp } from "react-icons/bs";
import { Grid } from "@mui/material";
const ShareLinks = () => {
  return (
    <Grid item container spacing={2} alignItems="center">
      <Grid item>
        <p>Share on</p>
      </Grid>
      <Grid item>
        <a
          href="#"
          onClick={() => {
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                window.location.href
              )}`,
              "facebook-share-dialog",
              "width=626,height=436"
            );
            return false;
          }}
        >
          <BsFacebook />
        </a>
      </Grid>
      <Grid item>
        <a
          href="#"
          onClick={() => {
            window.open(
              `https://twitter.com/share?text=${encodeURIComponent(
                window.location.href
              )}`,
              "twitter-share-dialog",
              "width=626,height=436"
            );
            return false;
          }}
        >
          <BsTwitter />
        </a>
      </Grid>
      <Grid item>
        <a
          href={`whatsapp://send?text=${encodeURIComponent(
            document.title + " " + window.location.href
          )}`}
        >
          <BsWhatsapp />
        </a>
      </Grid>
      <Grid item>
        <a
          href={`https://telegram.me/share/url?url=${encodeURIComponent(
            window.location.href
          )}`}
          target="_blank"
        >
          <BsTelegram />
        </a>
      </Grid>
    </Grid>
  );
};

export default ShareLinks;
