import React, { useEffect, useState } from "react";
import "./home.css";
// import { useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import TopicSection from "../../components/topic-section/topic-section";
import HotNews from "../../components/hot-news/hot-news";
import Category from "../../components/category/category";
import TopNews from "../../components/top-news/top-news";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import Footer from "../../components/footer/footer";

import Cartoon from "../../components/NewsArticalList/Cartoon";
import Special from "../../components/NewsArticalList/Special";
// import Gallery from "../../components/NewsArticalList/Gallery";
import Gallery from "../../components/Gallery/gallerry";

import UnderTopNews from "../../components/under-top-news/under-top-news";
import Gossip from "../../components/Gossip/Gossip";
import FooterAd from "../../components/FooterAd/FooterAd";
import Sports from "../../components/sports/sports";
import Business from "../../components/Business/business";
import Politics from "../../components/Politics/politics";
import WorldNews from "../../components/Worldnews/worldNews";

import newsData from "../../data/news-data";
import topNewsData from "../../data/top-news-data";
import gossipData from "../../data/gossip-data";
import specialData from "../../data/Special-data";
import underTopNewsData from "../../data/under-top-news-data";
import HotNewsTagLineData from "../../data/hot-news-tag-data";
import headnewsData from "../../data/head-news-data";
import hotnewsData from "../../data/hot-news-data";
import wheatherData from "../../data/weather-data";

import AdsArea from "../../components/ads-area/ads-area";
import HeadNewsSection from "../../components/head-news-section/head-news-section";

import data from "../../data/data";
import HotNewsTagLine from "../../components/hot-news-tag-line/hot-news-tag-line";

import NewsService, {
  getCategoryNews,
  getSortedCategoryNews,
} from "../../services/news/news.service";
import { viewCategoryHome1 } from "../../services/news/news.service";
import CategoryNewsHome from "../../components/CategoryNewsHome/CategoryNewsHome";
import common from "../../services/common";
import CategoryView from "../categoryView/categoryView";
import Loading from "../../components/shared/loading/loading";
import { ScrollRestoration } from "react-router-dom";

const Home = () => {
  const sportId = common.SportNewsId;
  const bussinessId = common.BusinessNewsId;
  const politicalId = common.PoliticalNewsId;
  const wordNewsId = common.WorldNewsId;
  const EnvirenmentId = common.EnvirenmentNewsId;
  const CartoonNewsId = common.CartoonNewsId;
  const NewItemsNewsId = common.NewItemsNewsId;
  const GossipNewsId = common.GossipNewsId;
  const [loading, setLoading] = useState(true);

  const [viewCat1, setViewCat1] = useState([]);
  const [recentNews, setRecentNews] = useState([]);
  const [sportNews, setSportNews] = useState([]);
  const [businessNews, setBusinessNews] = useState([]);
  const [politicalNews, setPoliticalNews] = useState([]);
  const [worldNews, setWorldNews] = useState([]);
  const [envirenmentNews, setEnvirenmentNews] = useState([]);
  const [cartoonNews, setCartoonNews] = useState([]);
  const [newItemsNews, setNewItemsNews] = useState([]);
  const [gossipNews, setGossipNews] = useState([]);
  const topics = data.topics;

  useEffect(() => {
    async function fetchCatFilterData() {
      await getSortedCategoryNews().then((res) => {
        if (res.data) {
          setLoading(false);
          setRecentNews(res.data.recent);
        }
      });
    }
    fetchCatFilterData();
  }, []);

  useEffect(() => {
    // Sport News
    async function getSportNews() {
      setLoading(true);
      await getCategoryNews(sportId).then((res) => {
        setLoading(false);
        setSportNews(res.data);
      });
    }

    // Business News
    async function getBusinesNews() {
      setLoading(true);
      await getCategoryNews(bussinessId).then((res) => {
        setLoading(false);
        setBusinessNews(res.data);
      });
    }
    // Political News
    async function getPoliticalNews() {
      setLoading(true);
      await getCategoryNews(politicalId).then((res) => {
        setLoading(false);
        setPoliticalNews(res.data);
      });
    }
    // World  News
    async function getWorldNews() {
      setLoading(true);
      await getCategoryNews(wordNewsId).then((res) => {
        setLoading(false);
        setWorldNews(res.data);
      });
    }

    // Envirenment  News
    async function getEnvironmentNews() {
      setLoading(true);
      await getCategoryNews(EnvirenmentId).then((res) => {
        setLoading(false);
        setEnvirenmentNews(res.data);
      });
    }

    // Cartoon  News
    async function getCartoomNews() {
      setLoading(true);
      await getCategoryNews(CartoonNewsId).then((res) => {
        setLoading(false);
        setCartoonNews(res.data);
      });
    }

    // Special News
    async function getSpecialNews() {
      setLoading(true);
      await getCategoryNews(NewItemsNewsId).then((res) => {
        setLoading(false);
        setNewItemsNews(res.data);
      });
    }

    // Gossip News
    async function getGossipNews() {
      setLoading(true);
      await getCategoryNews(GossipNewsId).then((res) => {
        setLoading(false);
        setGossipNews(res.data);
      });
    }

    getSportNews();
    getBusinesNews();
    getPoliticalNews();
    getWorldNews();
    getEnvironmentNews();
    getCartoomNews();
    getSpecialNews();
    getGossipNews();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      {/* =========Ads Area Section ============= */}
      <AdsArea />

      {/* =========First News Area Section ============= */}
      <HeadNewsSection recentFirstNews={recentNews[0]} />

      {/* =========Hot News Section ============= */}
      <HotNews hotnewsData={recentNews[1]} />

      {/* =========News Columns Section ============= */}
      <Container maxWidth="xl">
        <Grid item container>
          <Grid item md={12}>
            <TopNews newsData={recentNews} />
          </Grid>
        </Grid>
      </Container>

      {/* ========= News Category Section ============= */}
      <Container maxWidth="xl">
        <Grid item container spacing={2}>
          <Grid item md={3} xs={12}>
            <Grid item>
              {sportNews != undefined ? (
                <CategoryNewsHome category={sportNews} />
              ) : (
                <p>Loading...</p>
              )}
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid item>
              <CategoryNewsHome category={businessNews} />
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid item>
              <CategoryNewsHome category={politicalNews} />
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid item container></Grid>
            <Grid item>
              <CategoryNewsHome category={worldNews} />
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* =========Article Section ============= */}

      <Container maxWidth="xl">
        {/* =========Environmental News Section ============= */}
        <Grid container>
          <Grid item container pb={4}>
            <UnderTopNews underTopNewsData={envirenmentNews} />
          </Grid>
        </Grid>

        <Grid item container spacing={2} alignItems="stretch" justify="center">
          <Grid item md={4} style={{ height: "100%" }}>
            <Grid item container>
              <h2>දවසේ කාටුනය</h2>
            </Grid>
            <Grid item>
              <Cartoon cartoonData={cartoonNews} />
            </Grid>
          </Grid>
          <Grid item md={4} xs={12} style={{ height: "100%" }}>
            <Grid item container>
              <h2>විශේෂ</h2>
            </Grid>
            <Grid item>
              <Special specialData={newItemsNews} />
            </Grid>
          </Grid>
          <Grid item md={4} style={{ height: "100%" }}>
            <Grid item container>
              <h2>ගැලරිය</h2>
            </Grid>
            <Grid item>
              <Gallery />
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* =========Gossip News Section ============= */}
      <Container maxWidth="xl" className="text-left">
        <Grid container>
          <h2>ගොසිප්</h2>
        </Grid>
        <Grid container>
          <Gossip gossipData={gossipNews} />
        </Grid>
      </Container>

      {/* =========Footer Ad Section ============= */}
      <FooterAd />
    </div>
  );
};

export default Home;
