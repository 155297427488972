import { Button, Grid } from "@mui/material";
import React from "react";
import "./coming_soon.css";
import { Link } from "react-router-dom";

const Coming_soon = () => {
  return (
    <div class="wrapper coming-soon">
      <Grid container spacing={2}>
        <Grid item container justifyContent="center">
          <div class="maintenance">
            <div class="maintenance_contain">
              <img
                src="https://demo.wpbeaveraddons.com/wp-content/uploads/2018/02/main-vector.png"
                alt="maintenance"
              />
              <span class="pp-infobox-title-prefix">WE ARE COMING SOON</span>
              <div class="pp-infobox-title-wrapper">
                <h3 class="pp-infobox-title">The website under maintenance!</h3>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item container justifyContent="center">
          <Button variant="outlined" component={Link} to="/">
            Home
          </Button>
        </Grid>
      </Grid>

      {/* <p>Put some text here.</p> */}
      {/* <div class="icons">
        <a href="">
          <i class="fa fa-twitter"></i>
        </a>
        <a href="">
          <i class="fa fa-youtube-play"></i>
        </a>
        <a href="">
          <i class="fa fa-paper-plane"></i>
        </a>
      </div> */}
    </div>
  );
};

export default Coming_soon;
