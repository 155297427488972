import React from "react";
import { Container, Grid } from "@mui/material";

import "./footer.css";

import { BsPhone } from "react-icons/bs";

import { GoLocation } from "react-icons/go";

import { BiPhoneCall } from "react-icons/bi";

import Logo from "../../assets/images/Logo-Official.png";
import { Box } from "@mui/system";
import Social from "../shared/social/social";
import common from "../../services/common";
import { useState } from "react";
import { useEffect } from "react";
import { viewCategory } from "../../services/news/news.service";
import { Link } from "react-router-dom";

const Footer = () => {
  const [newsCategory, setNewsCategory] = useState([]);

  useEffect(() => {
    (async () => {
      const responseData = await viewCategory();
      setNewsCategory(responseData.data);
    })();
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container className="footer-colorline"></Grid>
      <Container maxWidth="xl">
        <Grid container py={{ md: 8, xs: 2 }}>
          <Grid item container md={5} xs={12}>
            <Grid item container textAlign={{ xs: "center", md: "left" }}>
              <Grid item pr={{ xs: 0, md: 8 }} pb={4}>
                <h3>
                  රටේ ලෝකයේ සිදුවන ප්‍රවෘත්ති වටිනාකමක් ඇති ඕනෑම
                  සිදුවීමක්නිවැරදිව එසැනින් ඉදිරිපත් කරන සයිබර් අවකාශයේ
                  විශ්වාසනීයම පුවත් ඉදිරිපත් කරන්නා
                </h3>
              </Grid>
              <Grid
                item
                container
                justifyContent={{ md: "flex-start", xs: "center" }}
              >
                <p>
                  <Link to="/">
                    {" "}
                    Powerd by <br /> <b>Newsz.lk</b>
                  </Link>
                </p>
              </Grid>
              <Grid
                item
                md={5}
                xs={12}
                justifyContent={{ md: "flex-start", xs: "center" }}
              >
                <Link to="/">
                  <img src={Logo} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={7} xs={12} justifyContent={{ xs: "center" }}>
            <Grid item md={3} xs={6}>
              <Grid item textAlign="left" className="footer-category-link">
                <div className="title">Categories</div>
                {newsCategory != undefined && (
                  <ul>
                    {newsCategory.map((item, index) => {
                      if (index > 9) return;
                      return (
                        <li key={index}>
                          <Link to={"/categoryView/?id=" + item._id}>
                            {item.category}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </Grid>
            </Grid>
            <Grid item md={3} xs={6}>
              <Grid item textAlign="left" className="footer-quick-link">
                <div className="title">Quick Links</div>
                <ul>
                  <li>
                    <Link to="/signup">Sign Up</Link>
                  </li>
                  <li>
                    <Link to="/signin">Sign In</Link>
                  </li>
                  <li>
                    <Link to="/coming-soon">About Us</Link>
                  </li>
                  <li>
                    <Link to="/coming-soon">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/coming-soon">ePaper</Link>
                  </li>
                  <li>
                    <Link to="/coming-soon">Archives</Link>
                  </li>
                  <li>
                    <Link to="/coming-soon">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/coming-soon">Privacy Policy</Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid item container md={6} className="footer-contact-link">
              <Grid item container textAlign="left" alignItems="center">
                <Grid item container>
                  <div className="title">Contact Us</div>
                </Grid>
                <Grid item container>
                  <ul>
                    <li>
                      <Link to="#">
                        <BsPhone /> +94123456789
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <GoLocation /> Australia
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <BiPhoneCall /> Colombo
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <BiPhoneCall /> Colombo
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Grid>
              <Grid item container textAlign="left" alignItems="center">
                <Grid item container>
                  <div className="title">Social Media</div>
                </Grid>
                <Grid item container>
                  <Social />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container justifyContent="center" className="footer-end-colorline">
        <Grid item className="footer-copyright-text">
          <p>
            © 2023 NEWSZ (PVT) LTD. All Right Reserved.
            <br />
            Design & Development by
            <a href="https://cyberstormasia.com/"> Cyberstorm Asia Pvt Ltd</a>
          </p>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
