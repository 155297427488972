import { Button, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CategoryNewsHome.css";
import { Link } from "react-router-dom";

const CategoryNewsHome = (data) => {
  const categoryDataArr = data.category;
  const categoryData = categoryDataArr.slice(0, 2);
  const homeLink = "/";
  return (
    <div className="CategoryNewsCom">
      {categoryData.length > 0 ? (
        <Grid container spacing={3}>
          <Grid item container>
            <h1>{categoryData[0].categoryId.category}</h1>
          </Grid>
          {categoryData.map((item, index) => {
            return (
              <Grid key={index} item container>
                <Grid item container xs={12}>
                  <Link to={"/detailNews/?id=" + item._id}>
                    {item.media[0] ? (
                      <img src={item.media[0]} />
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        className="skeleton"
                        style={{ height: "30vh" }}
                      />
                    )}
                  </Link>
                </Grid>
                <Grid item container>
                  <Link to={"/detailNews/?id=" + item._id}>
                    <h3 className="catTitle">{item.title}</h3>
                  </Link>
                </Grid>
              </Grid>
            );
          })}
          <Grid item container alignItems="center" justifyContent="flex-end">
            <Button
              component={Link}
              to={"/categoryView/?id=" + categoryData[0].categoryId._id}
              className="see-more-btn"
              variant="outlined"
              style={{
                backgroundColor: "#F2F2F2",
                borderRadius: "25px",
                marginRight: "48px",
              }}
            >
              See more
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItem="center">
          <Skeleton
            variant="rectangular"
            style={{ width: "75vw" }}
            height={200}
          />
        </Grid>
      )}
    </div>
  );
};

export default CategoryNewsHome;
