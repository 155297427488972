import React from "react";
import axios from "axios";
import commonVar from "./common";
import { toast } from "react-toastify";

const baseURL = commonVar.baseUrl;

export async function postReact(newsId, emojiId) {
  const token = localStorage.getItem("token");
  return axios
    .get(baseURL + "/news/updateReaction", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { newsId, emojiId, token },
    })
    .then((response) => {
      return (response = response.data);
    })
    .catch((error) => {
      if (error.response.status == 403) {
        localStorage.clear();
        toast.error("Please Login");
        window.location.href = "/signin";
      }
      console.error(error);
    });
}

export async function getReactions(newsId) {
  return axios
    .get(baseURL + `/reacts/get-reacts`, { params: { newsId } })
    .then((response) => {
      return (response = response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getTotalReact(newsId) {
  return axios
    .get(baseURL + `/news/getTotalReact`, { params: { newsId } })
    .then((response) => {
      return (response = response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}
