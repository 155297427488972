import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Grid } from "@mui/material";
import "./social.css";
import React, { useEffect, useState } from "react";
import { getSocialLinks } from "../../../services/shared.services";
import { Link } from "react-router-dom";

const Social = () => {
  const [socialResponse, setSocialResponse] = useState({
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    telegram: "",
    default: "",
  });

  useEffect(() => {
    async function fetchSocialMediaLinks() {
      await getSocialLinks().then((res) => {
        res.forEach((item) => {
          switch (item.sns) {
            case "Facebook":
              socialResponse.facebook = item.url;
              break;
            case "Twitter":
              socialResponse.twitter = item.url;
              break;
            case "Linkedin":
              socialResponse.linkedin = item.url;
              break;
            case "Instagram":
              socialResponse.instagram = item.url;
              break;
            case "Youtube":
              socialResponse.youtube = item.url;
              break;
            case "Telegram":
              socialResponse.telegram = item.url;
              break;
            default:
              socialResponse.default = "#";
              break;
          }
        });
      });
    }
    fetchSocialMediaLinks();
  }, []);

  return (
    <div className="social-links">
      <Grid container item direction="row" xs={12}>
        <div className="icon">
          <a href={socialResponse.facebook}>
            <FacebookIcon />
          </a>
          {/* <Link to={socialResponse.facebook} target="_blank">
            <FacebookIcon />
          </Link> */}
        </div>
        <div className="icon">
          <a href={socialResponse.twitter}>
            <TwitterIcon />
          </a>
          {/* <Link to={socialResponse.twitter} target="_blank">
            <TwitterIcon />
          </Link> */}
        </div>
        <div className="icon">
          <a href={socialResponse.linkedin}>
            <LinkedInIcon />
          </a>
          {/* <Link to={socialResponse.linkedin} target="_blank">
            <LinkedInIcon />
          </Link> */}
        </div>
        <div className="icon">
          <a href={socialResponse.instagram}>
            <InstagramIcon />
          </a>
          {/* <Link to={socialResponse.instagram} target="_blank">
            <InstagramIcon />
          </Link>  */}
        </div>
        <div className="icon">
          <a href={socialResponse.youtube}>
            <YouTubeIcon />
          </a>
          {/* <Link to={socialResponse.youtube} target="_blank">
            <YouTubeIcon />
          </Link> */}
        </div>
        <div className="icon">
          {/* <Link to={socialResponse.telegram} target="_blank">
            <TelegramIcon />
          </Link> */}
          <a href={socialResponse.telegram}>
            <TelegramIcon />
          </a>
        </div>
      </Grid>
    </div>
  );
};

export default Social;
