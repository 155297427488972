import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import "./header.css";
import logo from "../../assets/images/Logo-Official.png";
import Social from "../shared/social/social";
import Search from "../shared/search/search";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.services";
import { toast } from "react-toastify";

const Header = () => {
  const [date, setDate] = useState(new Date());
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();
  const [firstName, setfirstName] = useState();

  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setUserId(localStorage.getItem("userId"));
    setToken(localStorage.getItem("token"));
    setfirstName(localStorage.getItem("userFirstName"));
  }, []);

  const handleSignout = () => {
    AuthService.signOut().then((res) => {
      setTimeout(() => {
        toast.success("User Logout!");
      }, 1000);
      window.location.reload();
    });
  };
  return (
    <Box>
      <Grid item container className="head-colorline"></Grid>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} md={4}>
            <Grid item xs={12} md={4}>
              <h2>{date.toDateString()}</h2>
              <p>{date.toLocaleTimeString()}</p>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} py={4}>
            <Link to="/">
              <img src={logo} className="logo-img" />
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              item
              container
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Social />
              </Grid>
            </Grid>
            <Grid
              item
              container
              pb={4}
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <Search />
              </Grid>
              <Grid item xs={12} md={3}>
                {userId != undefined &&
                token != undefined &&
                firstName != undefined ? (
                  <>
                    <Button
                      className="nameButton"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setMenuOpen(true);
                      }}
                    >
                      {firstName}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={() => setMenuOpen(false)}
                    >
                      <MenuItem onClick={handleSignout}>Sign Out</MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    className="header-signin-btn"
                    onClick={() => navigate("/signin")}
                  >
                    Signin
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="header-bottom-color-bar"></div>
      </Container>
    </Box>
  );
};

export default Header;
