import { Box } from "@mui/system";
import React, { useState } from "react";
import "./signup.css";
import logo from "../../assets/images/Logo-Official.png";
import { Button, Card, CardContent, Grid } from "@mui/material";
import { BsGoogle } from "react-icons/bs";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/auth.services";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is Required!"),
    lastName: Yup.string().required("Last Name is Required!"),
    email: Yup.string().email().required("Email is Required!"),
    password: Yup.string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
  });

  const { values, errors, handleChange, handleSubmit, touched, setValues } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        // alert(JSON.stringify(values, null, 2));
        setLoading(true);
        try {
          AuthService.signUp(values).then((res) => {
            if (res.data.error) {
              toast.error(res.data.error);
            }
            if (res.data.token) {
              toast.success("User Registered! Please Check Email.");
              navigate(`/signin?token=${res.data.token}`);
            }
            setLoading(false);
          });
        } catch (error) {
          toast.error(error.message);
        }
      },
    });

  return (
    <div className="Signup">
      <Grid justifyContent="center">
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid item>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <p className="signuptag">Sign In With</p>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={4}
                  pb={4}
                  justifyContent="center"
                  alignItems="center"
                  className="social-login-btn"
                >
                  <Grid item md={6}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        className="gbtn"
                        onClick={AuthService.googleSignup}
                        style={{
                          borderRadius: "12px",
                          borderColor: "#C4C4C4",
                          color: "black",
                        }}
                      >
                        <BsGoogle className="social-login-icon" />
                        <p className="btntext">Google</p>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        className="fbtn"
                        style={{
                          borderRadius: "12px",
                          borderColor: "#C4C4C4",
                          color: "black",
                        }}
                      >
                        <FaFacebookF className="social-login-icon" />{" "}
                        <p className="btntext">Facebook</p>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justifyContent="center" spacing={2}>
                  <Grid item md={5}>
                    <hr />
                  </Grid>
                  <Grid item md={2}>
                    Or
                  </Grid>
                  <Grid item md={5}>
                    <hr />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="input-full-width"
                >
                  <Grid item container>
                    <h4>First Name</h4>
                  </Grid>
                  <Grid item container>
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      label="First Name"
                      variant="outlined"
                      type="Name"
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                      value={values.firstName}
                      onChange={handleChange("firstName")}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid item container>
                    <h4>Last Name</h4>
                  </Grid>
                  <Grid item container>
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      label="Last Name"
                      variant="outlined"
                      type="Name"
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                      value={values.lastName}
                      onChange={handleChange("lastName")}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item container>
                    <h4>Email</h4>
                  </Grid>
                  <Grid item container>
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      label="Email"
                      variant="outlined"
                      type="Email"
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                      value={values.email}
                      onChange={handleChange("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item container>
                    <h4>Password</h4>
                  </Grid>
                  <Grid item container>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Password"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        style: { borderRadius: "15px" },
                        endAdornment: (
                          <>
                            {showPassword ? (
                              <AiFillEye
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <AiFillEyeInvisible
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </>
                        ),
                      }}
                      value={values.password}
                      onChange={handleChange("password")}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>
                </Grid>
                <Grid item container className="SignUpButton" py={2}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    className="Signupbtn"
                    type="Submit"
                  >
                    <p className="SignBtnText">Sign Up</p>
                  </LoadingButton>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  className="Accountrequest"
                >
                  <p>
                    Already have an Account?
                    <Link to="/signin">
                      <b className="Signuptaglink"> Sign In</b>
                    </Link>
                  </p>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Grid>
    </div>
  );
};

export default Signup;
