import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  ScrollRestoration,
} from "react-router-dom";

import Signin from "./components/signin/signin";
import Home from "./screens/home/home";
import Signup from "./components/signup/signup";
import ForgotPasword from "./components/forgotpasword/forgotpasword";
import EmailVerify from "./components/emailverify/emailverify";
import ScreenNewsDetails from "./screens/news-details/screen-news-details";
import CategoryView from "./screens/categoryView/categoryView";
import Header from "./components/header/header";
import TopicSection from "./components/topic-section/topic-section";
import HotNewsTagLine from "./components/hot-news-tag-line/hot-news-tag-line";
import Footer from "./components/footer/footer";
import { Grid } from "@mui/material";
import ConfirmEmailVerification from "./components/confirmEmail/confirmEmailVerification";
import VerifyAccount from "./components/VerifyAccount/VerifyAccount";
import Empty_page from "./screens/page_404";
import ScrollToTop from "./components/ScrollToTop";
import Coming_soon from "./screens/coming_soon";
const HFLayout = ({ children }) => (
  <div>
    <Header />

    {/* =========Hot Headline Section ============= */}
    <TopicSection />

    {/* ========== Hot News Tag Line ============= */}
    <HotNewsTagLine />
    {children}
    {/* =========Footer Section ============= */}
    <Footer />
  </div>
);

const BasicLayout = ({ children }) => (
  <div>
    <Grid container alignItems="center" justifyContent="center">
      {children}
    </Grid>
  </div>
);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            exact
            element={
              <HFLayout>
                <Home />
              </HFLayout>
            }
          />

          <Route
            path="/signin"
            element={
              <BasicLayout>
                <Signin />
              </BasicLayout>
            }
          />
          <Route
            path="/signup"
            element={
              <BasicLayout>
                <Signup />
              </BasicLayout>
            }
          />
          <Route
            path="/forgetPassword"
            element={
              <BasicLayout>
                <ForgotPasword />
              </BasicLayout>
            }
          />
          <Route
            path="/emailVerify"
            element={
              <BasicLayout>
                <EmailVerify />
              </BasicLayout>
            }
          />

          <Route
            path={"/detailNews"}
            element={
              <HFLayout>
                <ScreenNewsDetails />
              </HFLayout>
            }
          />
          <Route
            path={"/categoryView"}
            element={
              <HFLayout>
                <CategoryView />
              </HFLayout>
            }
          />
          <Route
            path="/reset-password"
            element={<ConfirmEmailVerification />}
          />
          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/coming-soon" element={<Coming_soon />} />
          <Route path="*" element={<Empty_page />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
