import { Box } from "@mui/system";
import React from "react";
import "./forgotpasword.css";
import logo from "../../assets/images/Logo-Official.png";
import { Button, Card, CardContent, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthService from "../../services/auth.services";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
const Forgotpasword = () => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is Required!"),
  });

  const { values, errors, handleChange, handleSubmit, touched, setValues } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: () => {
        // alert(JSON.stringify(values, null, 2));
        setLoading(true);
        try {
          AuthService.resetPassword(values)
            .then((res) => {
              if (res.data.message) toast.success(res.data.message);
              setLoading(false);
            })
            .catch((error) => {
              toast.error(error.message);
            });
        } catch (error) {
          toast.error(error.message);
        }
      },
    });

  return (
    <div className="ForgotPassword">
      <Grid container justifyContent="center" pt={{ xs: 2, md: 8 }}>
        <Card sx={{ maxWidth: "100vw" }} md={{ maxWidth: "100vw" }}>
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Box p={4}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="fptag">Forgot Password</p>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className="resetlink">
                    Enter your account email to get a password reset link
                  </p>
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="input-full-width"
                >
                  <Grid item container>
                    <h4>Email</h4>
                  </Grid>

                  <Grid item container>
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      placeholder="Ex - abc@gmail.com"
                      variant="outlined"
                      type="Name"
                      InputProps={{
                        style: { borderRadius: "15px" },
                      }}
                      value={values.email}
                      onChange={handleChange("email")}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                </Grid>

                <Grid item container className="RequestButton" marginTop="20px">
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    className="reqbtn"
                    type="Submit"
                  >
                    <p className="reqBtnText">Request Code</p>
                  </LoadingButton>
                </Grid>
              </Box>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </div>
  );
};

export default Forgotpasword;
